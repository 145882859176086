// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-password_EyqjP{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;justify-content:space-between;width:100%;padding:12px;margin-top:26px;color:var(--TextDefault);cursor:pointer;background-color:var(--Layer1);border-radius:var(--BorderRadius)}.copy-password_EyqjP .copy-icon_dL6AS,.copy-password_EyqjP .lock-icon_deI5z{color:var(--TextSecondary)}.copy-password_EyqjP:focus{box-shadow:inset 0 0 0 1px var(--Blue)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy-password": `copy-password_EyqjP`,
	"copy-icon": `copy-icon_dL6AS`,
	"lock-icon": `lock-icon_deI5z`
};
export default ___CSS_LOADER_EXPORT___;
