// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-phone-check_IeSK6{padding:0 16px}.registration-phone-check__hint-block_wR6Td{margin-top:16px;cursor:default}.registration-phone-check__hint-block-content_SBIzw{margin-left:10px}.registration-phone-check__pseudo-link_SFeN1{-webkit-text-decoration:underline;text-decoration:underline;white-space:nowrap;cursor:pointer}.registration-phone-check__check-sms-code_DEq1D{height:24px;margin-bottom:20px;color:var(--Layer1);-webkit-text-decoration:underline;text-decoration:underline;letter-spacing:normal}html.app__layout--desktop .registration-phone-check_IeSK6{padding:0 32px 32px}html.app__layout--desktop .registration-phone-check__check-sms-code_DEq1D:hover{-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .registration-phone-check__pseudo-link_SFeN1:hover{-webkit-text-decoration:none;text-decoration:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration-phone-check": `registration-phone-check_IeSK6`,
	"registration-phone-check__hint-block": `registration-phone-check__hint-block_wR6Td`,
	"registration-phone-check__hint-block-content": `registration-phone-check__hint-block-content_SBIzw`,
	"registration-phone-check__pseudo-link": `registration-phone-check__pseudo-link_SFeN1`,
	"registration-phone-check__check-sms-code": `registration-phone-check__check-sms-code_DEq1D`
};
export default ___CSS_LOADER_EXPORT___;
