// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-acception_QqQIs{display:flex;align-items:flex-start;justify-content:space-between}.terms-acception__content_hM97F{flex:1}.terms-acception__button_XB8fY{width:24px;height:24px}.terms-acception__button-chevron_nxKgF{color:var(--TextSecondary);cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terms-acception": `terms-acception_QqQIs`,
	"terms-acception__content": `terms-acception__content_hM97F`,
	"terms-acception__button": `terms-acception__button_XB8fY`,
	"terms-acception__button-chevron": `terms-acception__button-chevron_nxKgF`
};
export default ___CSS_LOADER_EXPORT___;
