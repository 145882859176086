import { onBeforeMount, onBeforeUnmount, onMounted, ref, computed, toRef } from 'vue';
import { CustomerFieldType, CustomerRegistrationType } from '@leon-hub/api-sdk';
import { isEnumOfType } from '@leon-hub/guards';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { formatRegistrationFormFields, getDefaultCountryCode, saveActiveTabForLogin } from 'web/src/modules/registration/utils/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import RegistrationRouterParametersHolder from 'web/src/modules/registration/utils/RegistrationRouterParametersHolder';
import FormControlType from 'web/src/components/Form/enums/FormControlType';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import { isFormUiSchemaDefaultFields } from 'web/src/components/Form/guards';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import useCountryStore from 'web/src/modules/country/store/useCountryStore';
import useRegistrationIdleRedirectStore from 'web/src/modules/registration/store/useRegistrationIdleRedirectStore';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import getFormSnapshotFromFilledFields from 'web/src/modules/registration/utils/getFormSnapshotFromFilledFields';
import useDialogsStore from 'web/src/modules/dialogs/store/useDialogsStore';
import { EXCLUDED_COUNTRY_DIALOG_ID } from 'web/src/modules/registration/store/composables/useExcludedCountryDialog';
import { useRegistrationHook } from './useRegistrationHook';
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useMainStage(emits) {
    const simpleRegStore = useSimplifiedRegistrationStore();
    const siteConfigStore = useSiteConfigStore();
    const countryStore = useCountryStore();
    const regIdleStore = useRegistrationIdleRedirectStore();
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    const { showDialog } = useDialogs();
    const dialogsStore = useDialogsStore();
    const selectedCountryCode = ref(null);
    const { $translate } = useI18n();
    const formRef = ref();
    let predefinedEmail = null;
    const freeBetActionBannerUrl = toRef(simpleRegStore, 'freeBetActionBannerUrl');
    const bannerUrl = toRef(simpleRegStore, 'regBannerUrl');
    const registrationType = toRef(simpleRegStore, 'registrationType');
    const availableForms = toRef(simpleRegStore, 'availableForms');
    const customErrors = toRef(simpleRegStore, 'regFormCustomErrors');
    const isFormPending = toRef(simpleRegStore, 'isFormPending');
    const currentFormProperties = toRef(simpleRegStore, 'currentFormProperties');
    const formFilledFields = toRef(simpleRegStore, 'formFilledFields');
    const isRegistrationDisabled = toRef(siteConfigStore, 'isRegistrationDisabled');
    const geoIpCountryCode = toRef(countryStore, 'geoIpCountryCode');
    const currentDialog = toRef(dialogsStore, 'currentDialog');
    const isRegistrationHintMessageEnabled = toRef(()=>siteConfigStore.isRegistrationHintMessageEnabled);
    const registrationHintMessageLink = toRef(()=>siteConfigStore.registrationHintMessageLink);
    const schema = computed(()=>currentFormProperties.value?.schema);
    const uiSchema = computed(()=>{
        const currentSchema = currentFormProperties.value?.uiSchema;
        if (!currentSchema) return;
        const { fields } = {
            ...currentSchema
        };
        isFormUiSchemaDefaultFields(fields);
        if (fields.EMAIL && predefinedEmail) Object.assign(fields.EMAIL, {
            ...fields.EMAIL,
            default: predefinedEmail
        });
        const defaultCountryCode = getDefaultCountryCode(geoIpCountryCode.value, fields);
        const phoneValidationPattern = {
            '^[1-9][0-9]{4,14}$': $translate('WEB2_PHONE_INTERNATIONAL_FORMAT').value
        };
        return {
            ...currentSchema,
            fields: formatRegistrationFormFields({
                fields,
                selectedCountryCode: selectedCountryCode.value,
                isRegistrationDisabled: isRegistrationDisabled.value,
                defaultCountryCode
            }),
            submitButton: {
                label: $translate('WEB2_REGISTRATION_SUBMIT').value,
                disabled: isRegistrationDisabled.value
            },
            validatorErrorPatterns: {
                byWidget: phoneErrorPatterns.value,
                byPattern: {
                    [FormControlType.Phone]: phoneValidationPattern,
                    [FormControlType.PhoneInput]: phoneValidationPattern,
                    [FormControlType.PhoneCountriesSelectorInput]: phoneValidationPattern
                }
            }
        };
    });
    const isTermsCheckboxHidden = computed(()=>{
        const termsFieldCheckbox = uiSchema.value?.fields?.[CustomerFieldType.TERMS_CHECKBOX];
        if (termsFieldCheckbox?.hidden !== void 0) return termsFieldCheckbox.hidden;
        return true;
    });
    const freeBetBannerUrl = computed(()=>freeBetActionBannerUrl.value ?? null);
    const tabsItems = computed(()=>availableForms.value.map((form)=>form.registrationType).map((formKey)=>({
                id: formKey,
                label: formKey === CustomerRegistrationType.SIMPLE_EMAIL ? $translate('WEB2_REG_TAB_NAME_EMAIL').value : $translate('WEB2_REG_TAB_NAME_PHONE').value
            })));
    const tabsProperties = computed(()=>({
            items: tabsItems.value,
            activeId: registrationType.value ?? void 0,
            fullWidth: true,
            type: TabsType.UPPERCASE
        }));
    const isLargerPaddingBottom = computed(()=>currentDialog.value?.dialog?.id === EXCLUDED_COUNTRY_DIALOG_ID);
    function saveCurrentSnapshot() {
        const snapshotToSave = formRef.value?.getSnapshot();
        if (snapshotToSave) simpleRegStore.saveFormFilledFields(snapshotToSave.formData);
    }
    function onTabClick(tabName) {
        isEnumOfType(CustomerRegistrationType)(tabName);
        saveCurrentSnapshot();
        simpleRegStore.setCurrentRegistrationType(tabName);
    }
    function emitInput(data) {
        emits('input', data);
    }
    function onSubmit(data) {
        if (!registrationType.value) return;
        if (registrationType.value === CustomerRegistrationType.SIMPLE_PHONE) simpleRegStore.setMetrikaPhoneCheck('clickRegistrationButton');
        simpleRegStore.setFormPendingStatus(true);
        saveActiveTabForLogin(registrationType.value);
        saveCurrentSnapshot();
        emits('submit', data);
    }
    function showModalIsRegistrationDisabled() {
        if (isRegistrationDisabled.value) showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: $translate('WEB2_REGISTRATION_DISABLED_TITLE').value,
                confirmMessage: $translate('WEB2_REGISTRATION_DISABLED').value,
                iconName: void 0,
                isCloseAllowed: true,
                buttons: [
                    {
                        label: $translate('WEB2_REGISTRATION_DISABLED_BTN').value,
                        action: DialogAction.CONFIRM
                    }
                ]
            }
        });
    }
    useRegistrationHook();
    onBeforeMount(()=>{
        const email = RegistrationRouterParametersHolder.getInputEmail();
        if (email) {
            simpleRegStore.setCurrentRegistrationType(CustomerRegistrationType.SIMPLE_EMAIL);
            predefinedEmail = email;
            RegistrationRouterParametersHolder.setInputEmail(void 0);
        }
    });
    onMounted(()=>{
        regIdleStore.setStartTimeRegistration(Date.now());
        showModalIsRegistrationDisabled();
    });
    onBeforeUnmount(()=>{
        saveCurrentSnapshot();
    });
    function restoreFromSnapshot() {
        const snapshot = getFormSnapshotFromFilledFields(formFilledFields.value);
        if (snapshot) formRef.value?.restoreFromSnapShot(snapshot);
    }
    return {
        formRef,
        isTermsCheckboxHidden,
        freeBetBannerUrl,
        bannerUrl,
        tabsItems,
        tabsProperties,
        onTabClick,
        onSubmit,
        schema,
        uiSchema,
        customErrors,
        isFormPending,
        emitInput,
        restoreFromSnapshot,
        isLargerPaddingBottom,
        isRegistrationHintMessageEnabled,
        registrationHintMessageLink
    };
}
