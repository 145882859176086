// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration__freebet-banner_QQ7vl{display:block;margin:8px;overflow:hidden;border-radius:5px}.registration__freebet-banner-img_txb_t{display:block;max-width:100%}html.app__layout--desktop .registration__freebet-banner_QQ7vl{margin:8px 24px}.registration__tabs_sHtlu{padding:0 8px;margin-bottom:16px}html.app__layout--desktop .registration__tabs_sHtlu{padding:0 24px}.registration__content_OEIIt{padding:0 16px}html.app__layout--desktop .registration__content_OEIIt{padding:0 32px 32px}html.app__layout--desktop .registration__content--larger-padding-bottom_NSth8{padding-bottom:100px}.registration__form_YCvYs{margin-bottom:20px}.registration__disclaimer_XQ3qc{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:20px;color:var(--TextPrimary)}.registration__disclaimer_XQ3qc a{-webkit-text-decoration:none;text-decoration:none}.registration__disclaimer_XQ3qc a span{display:inline-block;-webkit-text-decoration:underline;text-decoration:underline}.registration__disclaimer_XQ3qc a span:first-letter{text-transform:uppercase}.registration__disclaimer_XQ3qc a:hover{color:var(--BrandHighlight)}.registration__hint-message-link_GVHf_{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;text-align:center}.registration__hint-message-link_GVHf_ p{margin:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration__freebet-banner": `registration__freebet-banner_QQ7vl`,
	"registration__freebet-banner-img": `registration__freebet-banner-img_txb_t`,
	"registration__tabs": `registration__tabs_sHtlu`,
	"registration__content": `registration__content_OEIIt`,
	"registration__content--larger-padding-bottom": `registration__content--larger-padding-bottom_NSth8`,
	"registration__form": `registration__form_YCvYs`,
	"registration__disclaimer": `registration__disclaimer_XQ3qc`,
	"registration__hint-message-link": `registration__hint-message-link_GVHf_`
};
export default ___CSS_LOADER_EXPORT___;
