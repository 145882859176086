// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-success_qdljY{padding:0 16px;margin-top:16px}.registration-success__jumbotron_cDnAS{color:var(--BrandDefault)}.registration-success__change-password_GYl_1{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;height:auto;padding:4px 0 10px;color:var(--TextSecondary);-webkit-text-decoration:underline;text-decoration:underline;text-transform:lowercase}.registration-success__change-password_GYl_1:focus{color:var(--BrandHighlight);outline:none}.registration-success__congrats_mrRnn{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.registration-success__tooltip_OPizU{top:calc(50% - 60px)}html.app__layout--desktop .registration-success_qdljY{padding:0 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration-success": `registration-success_qdljY`,
	"registration-success__jumbotron": `registration-success__jumbotron_cDnAS`,
	"registration-success__change-password": `registration-success__change-password_GYl_1`,
	"registration-success__congrats": `registration-success__congrats_mrRnn`,
	"registration-success__tooltip": `registration-success__tooltip_OPizU`
};
export default ___CSS_LOADER_EXPORT___;
