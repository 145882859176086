import { onBeforeMount, ref, computed, toRef } from 'vue';
import { CustomerRegistrationType, PhoneCodeMethod, RegistrationStateExceptionCode } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { CustomCustomerFieldType, RegistrationFieldName, RegistrationStage, RegistrationFormStep } from 'web/src/modules/registration/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import FormControlType from 'web/src/components/Form/enums/FormControlType';
import FormFocusType from 'web/src/components/Form/enums/FormFocusType';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
export function usePhoneCheckStage(emits) {
    const siteConfigStore = useSiteConfigStore();
    const simpleRegStore = useSimplifiedRegistrationStore();
    const { $translate } = useI18n();
    const phoneCodeLengthSettings = toRef(siteConfigStore, 'phoneCodeLengthSettings');
    const suggestedEmailRegistrationTimeout = toRef(siteConfigStore, 'suggestedEmailRegistrationTimeout');
    const isSuggestedEmailRegistrationEnabled = toRef(siteConfigStore, 'isSuggestedEmailRegistrationEnabled');
    const phoneCheckType = toRef(simpleRegStore, 'phoneCheckType');
    const configuredCaptchaUiSchema = toRef(()=>simpleRegStore.configuredCaptchaUiSchema);
    const isRegistrationCaptchaV3Enabled = toRef(()=>simpleRegStore.isRegistrationCaptchaV3Enabled);
    const isFormPending = toRef(simpleRegStore, 'isFormPending');
    const submittedStarredPhone = toRef(simpleRegStore, 'submittedPhoneStarred');
    const resendSmsTimeOut = toRef(simpleRegStore, 'resendSmsTimeOut');
    const customErrors = toRef(simpleRegStore, 'regFormCustomErrors');
    const prevFormData = toRef(simpleRegStore, 'prevFormData');
    const registrationType = toRef(simpleRegStore, 'registrationType');
    const currentFormProperties = toRef(simpleRegStore, 'currentFormProperties');
    const errorConverter = useErrorsConverter();
    const showHintBlock = ref(false);
    const phoneCheckConfirmationCodeLength = computed(()=>phoneCodeLengthSettings.value[phoneCheckType.value] || 6);
    onBeforeMount(()=>{
        simpleRegStore.isTimerRestarted();
    });
    const schema = computed(()=>{
        const codeLength = phoneCheckConfirmationCodeLength.value;
        return {
            $schema: 'http://json-schema.org/draft-07/schema#',
            type: 'object',
            title: 'phoneCheck',
            required: [
                RegistrationFieldName.SMS_CODE
            ],
            properties: {
                [RegistrationFieldName.SMS_CODE]: {
                    type: 'string',
                    pattern: `^[0-9]{0,${codeLength}}$`,
                    minLength: codeLength,
                    maxLength: codeLength
                },
                [CustomCustomerFieldType.CAPTCHA_TOKEN]: {
                    type: 'string'
                },
                [CustomCustomerFieldType.CAPTCHA_TYPE]: {
                    type: 'string'
                },
                ...isRegistrationCaptchaV3Enabled.value ? {
                    [CustomCustomerFieldType.CAPTCHA_TOKEN_V3]: {
                        type: 'string'
                    }
                } : {}
            }
        };
    });
    const uiSchema = computed(()=>({
            order: [
                RegistrationFieldName.SMS_CODE,
                CustomCustomerFieldType.CAPTCHA_TOKEN,
                CustomCustomerFieldType.CAPTCHA_TYPE,
                ...isRegistrationCaptchaV3Enabled.value ? [
                    CustomCustomerFieldType.CAPTCHA_TOKEN_V3
                ] : []
            ],
            submitButton: {
                label: $translate('WEB2_REGISTRATION_PHONE_CHECK_NEXT').value
            },
            fields: {
                [RegistrationFieldName.SMS_CODE]: {
                    title: $translate('WEB2_SMS_CODE_FIELD_TITLE').value,
                    widget: FormControlType.SmsCode,
                    options: {
                        size: phoneCheckConfirmationCodeLength.value
                    }
                },
                ...configuredCaptchaUiSchema.value,
                ...isRegistrationCaptchaV3Enabled.value ? simpleRegStore.getConfiguredCaptchaV3UiSchema({
                    action: 'reg_phone_check'
                }).value : {}
            },
            focus: {
                type: FormFocusType.OnMount,
                field: RegistrationFieldName.SMS_CODE
            }
        }));
    const isPhoneCheckByCall = computed(()=>phoneCheckType.value === PhoneCodeMethod.CALL_PASSWORD_CODE);
    function emitCheckSmsCode(data) {
        emits('check-sms-code', data);
    }
    function setRetrySmsCodeTime() {
        simpleRegStore.restartResendSmsTimeout();
    }
    function changeShowHintBlock(value) {
        showHintBlock.value = value;
    }
    const isShowHintBlockVisible = computed(()=>isSuggestedEmailRegistrationEnabled.value && showHintBlock.value);
    function emitInput(payload) {
        emits('input', payload);
    }
    function goToChangePhone() {
        simpleRegStore.goToStage({
            stage: RegistrationStage.ChangePhone
        });
    }
    function goToEmailRegistration() {
        simpleRegStore.setCurrentRegistrationType(CustomerRegistrationType.SIMPLE_EMAIL);
        simpleRegStore.goToStage({
            stage: RegistrationStage.Main
        });
    }
    function goToPhoneCheckBySmsStage() {
        simpleRegStore.setCurrentRegistrationType(CustomerRegistrationType.SIMPLE_PHONE);
        simpleRegStore.goToStage({
            stage: RegistrationStage.Main
        });
    }
    async function resendPhoneCodeByType(data) {
        if (simpleRegStore.isTimerRestarted()) {
            const form = {
                formData: {
                    ...prevFormData.value,
                    code: '',
                    step: RegistrationFormStep.Initial,
                    ...isRegistrationCaptchaV3Enabled.value && data?.formData?.[CustomCustomerFieldType.CAPTCHA_TOKEN_V3] ? {
                        [CustomCustomerFieldType.CAPTCHA_TOKEN_V3]: data?.formData?.[CustomCustomerFieldType.CAPTCHA_TOKEN_V3]
                    } : {}
                },
                errors: null,
                customErrors: null,
                field: ''
            };
            if (registrationType.value) try {
                await simpleRegStore.onResendSmsCode(form, {
                    registrationType: registrationType.value,
                    schemaId: currentFormProperties.value?.schemaId ?? ''
                }, phoneCheckType.value);
            } catch (rawError) {
                const error = errorConverter.convertToBaseError(rawError);
                if (error.code.equals(RegistrationStateExceptionCode.REGISTRATION_STATE_ERROR)) {
                    await simpleRegStore.resetRegistrationState();
                    simpleRegStore.goToStage({
                        stage: RegistrationStage.Main,
                        clearHistory: true
                    });
                }
            }
        }
    }
    return {
        schema,
        uiSchema,
        isFormPending,
        submittedStarredPhone,
        resendSmsTimeOut,
        customErrors,
        suggestedEmailRegistrationTimeout,
        isPhoneCheckByCall,
        emitCheckSmsCode,
        setRetrySmsCodeTime,
        isShowHintBlockVisible,
        changeShowHintBlock,
        emitInput,
        goToChangePhone,
        goToEmailRegistration,
        goToPhoneCheckBySmsStage,
        resendPhoneCodeByType
    };
}
