// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .change-password-success_DYbg6{padding:0 32px 32px}html.app__layout--desktop .change-password-success_DYbg6>.change-password-success__form_qvDrq{margin-top:16px}html.app__layout--desktop .change-password-success__jumbotron_WRjN3{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"change-password-success": `change-password-success_DYbg6`,
	"change-password-success__form": `change-password-success__form_qvDrq`,
	"change-password-success__jumbotron": `change-password-success__jumbotron_WRjN3`
};
export default ___CSS_LOADER_EXPORT___;
