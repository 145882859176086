// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .registration-change-phone_gVzCr{padding:0 32px 32px}html.app__layout--desktop .registration-change-phone__form_bqtTh{margin-top:20px}html.app__layout--desktop .registration-change-phone__hint_khUOw{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-top:16px;color:var(--TextPrimary);text-align:center}html.app__layout--desktop .registration-change-phone__hint--current-phone_PbnBD{color:var(--TextDefault);display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration-change-phone": `registration-change-phone_gVzCr`,
	"registration-change-phone__form": `registration-change-phone__form_bqtTh`,
	"registration-change-phone__hint": `registration-change-phone__hint_khUOw`,
	"registration-change-phone__hint--current-phone": `registration-change-phone__hint--current-phone_PbnBD`
};
export default ___CSS_LOADER_EXPORT___;
