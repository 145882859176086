import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { RegistrationStage } from 'web/src/modules/registration/enums';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useRegistrationShowLeaveModal } from 'web/src/modules/registration/submodules/composables/useRegistrationShowLeaveModal';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDesktopModalCloseEvent } from 'web/src/modules/core/composables/router';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useSimpleRegistrationTopBarRouteComponent() {
    const { $translate } = useI18n();
    const router = useRouter();
    const { showLeaveModal } = useRegistrationShowLeaveModal();
    const simplifiedRegistrationStore = useSimplifiedRegistrationStore();
    const siteConfigStore = useSiteConfigStore();
    const registrationStage = toRef(()=>simplifiedRegistrationStore.stage);
    const isExcludedCountry = toRef(()=>simplifiedRegistrationStore.isExcludedCountry);
    const previousStages = toRef(()=>simplifiedRegistrationStore.previousStages);
    const showModalOnLeaveEnabled = toRef(()=>siteConfigStore.showModalOnLeaveEnabled);
    const disableBackArrowForStages = [
        RegistrationStage.Main
    ];
    const title = computed(()=>{
        switch(registrationStage.value){
            case RegistrationStage.ChangePasswordSuccess:
                return '';
            case RegistrationStage.ChangePassword:
                return $translate('WEB2_REGISTRATION_CHANGE_PASSWORD_NAV_TITLE').value;
            case RegistrationStage.ChangePhone:
                return $translate('WEB2_REGISTRATION_CHANGE_PHONE_NAV_TITLE').value;
            case RegistrationStage.PhoneCheck:
            case RegistrationStage.PhoneCheckByCall:
                return $translate('WEB2_REGISTRATION_PHONE_CHECK_NAV_TITLE').value;
            case RegistrationStage.Success:
                return $translate('WEB2_REGISTRATION_SUCCESS_NAV_TITLE').value;
            default:
                return $translate('WEB2_REGISTRATION_NAV_TITLE').value;
        }
    });
    const isBackArrowActive = computed(()=>!disableBackArrowForStages.includes(registrationStage.value) && previousStages.value.length > 0);
    function closeCallback() {
        if (registrationStage.value === RegistrationStage.PhoneCheck) {
            simplifiedRegistrationStore.setInitialAvailableForm();
            simplifiedRegistrationStore.goToStage({
                stage: RegistrationStage.Main
            });
        }
        router.back();
    }
    function onBack() {
        if (!showModalOnLeaveEnabled.value) return;
        if (registrationStage.value !== RegistrationStage.Main || isExcludedCountry.value) closeCallback();
        else showLeaveModal(void 0, closeCallback);
    }
    function onClose() {
        if (!showModalOnLeaveEnabled.value) return;
        if (registrationStage.value === RegistrationStage.ChangePassword) router.back();
        else router.closeModal();
    }
    if (!isExcludedCountry.value) useDesktopModalCloseEvent((event)=>showLeaveModal(()=>event.preventDefault()));
    return {
        title,
        showModalOnLeaveEnabled,
        onClose,
        onBack,
        isBackArrowActive
    };
}
