import { onBeforeUnmount, computed, toRef } from 'vue';
import { CustomerFieldType } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import FormControlType from 'web/src/components/Form/enums/FormControlType';
import { ButtonKind, ButtonType } from 'web/src/components/Button/VButton/enums';
import hasAnyFormErrors from 'web/src/modules/registration/utils/hasAnyFormErrors';
import { useRegistrationStore } from 'web/src/modules/registration/utils/useRegistrationStore';
export function useChangePhoneNumberStage(emits) {
    const regStore = useRegistrationStore();
    const { $translate } = useI18n();
    const phone = useFormDefaultFormPhoneValue().getDefaultPhoneValueForForm();
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    const submittedPhoneStarred = toRef(regStore, 'submittedPhoneStarred');
    const isFormPending = toRef(regStore, 'isFormPending');
    const customErrors = toRef(regStore, 'regFormCustomErrors');
    const configuredCaptchaUiSchema = toRef(regStore, 'configuredCaptchaUiSchema');
    const isRegistrationCaptchaV3Enabled = toRef(()=>regStore.isRegistrationCaptchaV3Enabled);
    function emitInput(payload) {
        emits('input', payload);
    }
    onBeforeUnmount(()=>{
        regStore.setFormPendingStatus(false);
    });
    const schema = toRef(()=>({
            $schema: 'http://json-schema.org/draft-07/schema#',
            type: 'object',
            title: 'RegistrationChangePhone',
            required: [
                CustomerFieldType.PHONE_INPUT
            ],
            properties: {
                [CustomerFieldType.PHONE_INPUT]: {
                    properties: {
                        prefix: {
                            type: 'string',
                            pattern: '^[0-9]{0,4}$'
                        },
                        suffix: {
                            type: 'string',
                            pattern: '^[1-9][0-9]{4,14}$'
                        }
                    },
                    required: [
                        'prefix',
                        'suffix'
                    ],
                    type: 'object'
                },
                [CustomCustomerFieldType.CAPTCHA_TOKEN]: {
                    type: 'string'
                },
                [CustomCustomerFieldType.CAPTCHA_TYPE]: {
                    type: 'string'
                },
                ...isRegistrationCaptchaV3Enabled.value ? {
                    [CustomCustomerFieldType.CAPTCHA_TOKEN_V3]: {
                        type: 'string'
                    }
                } : {}
            }
        }));
    const uiSchema = computed(()=>({
            order: [
                CustomerFieldType.PHONE_INPUT,
                CustomCustomerFieldType.CAPTCHA_TOKEN,
                CustomCustomerFieldType.CAPTCHA_TYPE,
                ...isRegistrationCaptchaV3Enabled.value ? [
                    CustomCustomerFieldType.CAPTCHA_TOKEN_V3
                ] : []
            ],
            fields: {
                [CustomerFieldType.PHONE_INPUT]: {
                    widget: FormControlType.Phone,
                    options: {
                        placeholder: $translate('WEB2_PHONE_INPUT_PLACEHOLDER').value
                    },
                    title: $translate('JSPNAV_CONTACTS_REQUEST_PHONE_NUMBER').value,
                    default: phone
                },
                ...configuredCaptchaUiSchema.value,
                ...isRegistrationCaptchaV3Enabled.value ? regStore.getConfiguredCaptchaV3UiSchema({
                    action: 'reg_change_phone_number'
                }).value : {}
            },
            submitButton: {
                label: $translate('WEB2_REGISTRATION_CHANGE_PHONE_SUBMIT').value,
                disabled: false,
                type: ButtonType.SUBMIT,
                kind: ButtonKind.PRIMARY,
                isLoading: isFormPending.value,
                fullWidth: true
            },
            validatorErrorPatterns: {
                byWidget: phoneErrorPatterns.value
            }
        }));
    function onChangePhoneSubmit(data) {
        if (!hasAnyFormErrors(data)) emits('change-phone', data);
    }
    return {
        isFormPending,
        uiSchema,
        schema,
        customErrors,
        submittedPhoneStarred,
        emitInput,
        onChangePhoneSubmit
    };
}
